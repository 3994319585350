<template>
  <v-card height="100vh">
    <v-toolbar color="grey darken-3" dark elevation="1" height="64">
      <v-toolbar-title>
        {{ title }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              class="grey darken-3"
              icon
              outlined
              :disabled="isNew"
              v-bind="attrs"
              v-on="on"
              :loading="getModalLoading"
              @click="menu = true"
          >
            <v-icon color="secondary">mdi-arrow-down-drop-circle-outline</v-icon>
          </v-btn>
        </template>
        <v-list dense rounded nav>
          <v-list-item link class="px-4" @click.stop="deleteDialog">
            <v-list-item-icon>
              <v-icon color="error lighten-1">mdi-delete-forever-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="font-weight-medium">Вилучити</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-navigation-drawer
        right
        app
        width="400"
        v-model="document_state.show"
        style="z-index: 9999"
        color="grey lighten-4"
    >
      <v-card tile flat class="transparent">
        <v-card-text class="pa-0">
          <div style="display: flex; align-items: center" class="py-2 pl-3 pr-1 grey darken-2 white--text">
            <div style="flex: 1; font-weight: 500;">Перелік помилок заповнення</div>
            <div style="flex: 0 0 30px">
              <v-btn icon small @click.stop="document_state.show = false" class=" grey darken-2">
                <v-icon color="secondary">mdi-close</v-icon>
              </v-btn>
            </div>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text class="px-2 pt-2 pb-2">
          <div>
            <div style="display: flex; margin-bottom: 5px; padding: 3px; border-radius: 5px;"
                 class="elevation-3 white"
                 v-for="(item, item_idx) in document_state.errors"
                 :key="`error-${item_idx}`"
            >
              <div class="py-1 px-2">
                <div class="mb-1">
                  <v-chip small color="success" label class="mr-1" style="height: 20px !important;">
                    {{ item.table }}
                  </v-chip>
                  <v-chip small color="grey lighten-3" label class="mr-1" style="height: 20px !important;"
                          v-if="item.row_num">
                    {{ `№ рядка: ${item.row_num}` }}
                  </v-chip>
                </div>
                <div style="color: #2d2d2d; font-weight: 400; font-size: .76rem">
                  {{ item.text }}
                </div>
              </div>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-navigation-drawer>
    <v-bottom-sheet
        right
        app
        v-model="show_work_table"
        disable-route-watcher
        persistent
        bottom
        width="900"
    >
      <v-card min-height="80vh" class="accounting-document pretty px-4 py-2" style="overflow: hidden; position: relative">
        <div class="close-button-for-bottom-sheet" style="position: absolute; top: 0; right: 0">
          <v-btn small depressed class="grey lighten-4" @click="closeWorkTable">
            <v-icon size="19" color="grey darken-2">mdi-close</v-icon>
          </v-btn>
        </div>
        <div class="command-panel mt-2">
          <v-btn small
                 dark
                 class="success mr-2" depressed
                 @click.stop="addWorkTableRow"
          >
            <v-icon small>mdi-plus</v-icon>
          </v-btn>
          <v-btn small
                 class="grey lighten-3 mr-2" depressed
                 @click.stop="clearWorkTableDialogCopy"
          >
            <v-icon small color="error">mdi-trash-can-outline</v-icon>
          </v-btn>
          <v-btn small
                 class="grey lighten-3 mr-2" depressed
                 @click.stop="showDispatcherProblem('work_table')"
          >
            <v-icon small left color="teal" t>mdi-receipt-text-plus-outline</v-icon>
            Підбір
          </v-btn>
          <v-btn small
                 class="grey lighten-3" depressed
                 @click.stop="acceptWorkTable"
          >
            <v-icon small left color="secondary">mdi-check-all</v-icon>
            Підтвердити
          </v-btn>
        </div>
        <div class="document-table with-command mt-2" style="height: calc(80vh - 30px)">
          <div class="document-table-body">
            <div class="document-table-header">
              <div class="document-table-row">
                <div class="document-table-col" style="flex: 0 0 70px">
                  № п/п
                </div>
                <div class="document-table-col" style="flex: 0 0 300px">
                  Робота
                </div>
                <div class="document-table-col" style="flex: 0 0 60px">
                  Платна
                </div>
                <div class="document-table-col" style="flex: 0 0 120px">
                  Кількість
                </div>
                <div class="document-table-col" style="flex: 0 0 120px">
                  Ціна
                </div>
                <div class="document-table-col" style="flex: 0 0 120px">
                  Сума
                </div>
              </div>
            </div>
            <div class="document-row-body" style="height: calc(100% - 100px); overflow: auto">
              <div class="document-table-row"
                   v-for="(charge) in work_table_copy"
                   :key="`charge-${charge.row_num}`"
              >
                <div class="document-table-col" style="flex: 0 0 70px; display: flex">
                  <div style="flex: 1; padding-left: 4px">
                    {{ work_table_copy.indexOf(charge) + 1 }}
                  </div>
                  <div style="flex:  0 0 32px">
                    <v-btn icon small class="grey lighten-3" @click="removeWorkTableRow(charge)">
                      <v-icon small color="grey darken-2">mdi-delete</v-icon>
                    </v-btn>
                  </div>
                </div>
                <div class="document-table-col" style="flex: 0 0 300px">
                  <select_input
                      v-model="charge.dispatcher_problem_id"
                      :computed-action="'SELECT_DISPATCHER_PROBLEM_LIST_ELEMENTS'"
                      :computed-getter="'get_dispatcher_problem_list_select'"
                      :input-value-as-value="false"
                      :item-search="'text'"
                      :show-on-focus="false"
                      :row_num="charge.row_num"
                      :show-select-on-focus="false"
                      :show-search-on-focus="true"
                      :action-props="{is_group: false}"
                      small
                      transparent
                      :select-button-object="{
                                name: 'DISPATCHER_PROBLEM_ELEMENTS',
                                group: 'DISPATCHER_PROBLEM_GROUP',
                                hierarchy: true,
                                only_groups: false,
                                title: 'Список проблем/робіт диспетчерської',
                                element_title: 'Проблема/робота диспетчерської',
                                action_props: {},
                                show: true
                              }"
                      :required="true"
                      @selectChange="afterNomenclatureSelect"
                  />
                </div>
                <div class="document-table-col" style="flex: 0 0 60px">
                  <v-simple-checkbox v-model="charge.pay_service" style="margin-left: 8px"
                                     color="success" :ripple="false"/>
                </div>
                <div class="document-table-col" style="flex: 0 0 120px">
                  <number_input :row_num="charge.row_num" v-model="charge.count" v-decimal transparent
                                small field="count"
                                :required="true"
                                v-if="charge.pay_service"
                                @userChange="workTableColChange"
                  />
                </div>
                <div class="document-table-col" style="flex: 0 0 120px">
                  <number_input :row_num="charge.row_num" v-model="charge.price" v-decimal transparent
                                small field="price"
                                :required="true"
                                v-if="charge.pay_service"
                                @userChange="workTableColChange"
                  />
                </div>
                <div class="document-table-col" style="flex: 0 0 120px">
                  <number_input :row_num="charge.row_num" v-model="charge.sum" v-decimal transparent
                                small field="sum"
                                :required="true"
                                v-if="charge.pay_service"
                                @userChange="workTableColChange"
                  />
                </div>
              </div>
            </div>
            <div class="document-table-footer mt-2">
              <div class="document-table-row">
                <div class="document-table-col"
                     :style="`flex: 0 0 430px`"
                     style="text-align: right; padding-right: 6px">
                  Всього:
                </div>
                <div class="document-table-col" style="flex: 0 0 120px; padding-left: 10px">
                  {{ footer_total.work_table.count | formatToFixed | formatNumber }}
                </div>
                <div class="document-table-col" style="flex: 0 0 120px; padding-left: 10px">
                </div>
                <div class="document-table-col" style="flex: 0 0 120px; padding-left: 10px">
                  {{ footer_total.work_table.sum | formatToFixed | formatNumber }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-card>
    </v-bottom-sheet>
    <v-bottom-sheet
        right
        app
        v-model="show_change_data_table"
        disable-route-watcher
        persistent
        bottom
        width="900"
    >
      <v-card min-height="80vh" class="accounting-document pretty px-4 py-2" style="overflow: hidden; position: relative">
        <div class="close-button-for-bottom-sheet" style="position: absolute; top: 0; right: 0">
          <v-btn small depressed class="grey lighten-4" @click="closeChangeDataTable">
            <v-icon size="19" color="grey darken-2">mdi-close</v-icon>
          </v-btn>
        </div>
        <div class="command-panel mt-2">
          <v-btn small
                 dark
                 class="success mr-2" depressed
                 @click.stop="addChangeDataTableRow"
          >
            <v-icon small>mdi-plus</v-icon>
          </v-btn>
          <v-btn small
                 dark
                 class="grey lighten-3 mr-2" depressed
                 @click.stop="clearChangeDataTableDialogCopy"
          >
            <v-icon small color="error">mdi-trash-can-outline</v-icon>
          </v-btn>
          <v-btn small
                 class="grey lighten-3" depressed
                 @click.stop="acceptChangeDataTable"
          >
            <v-icon small left color="secondary">mdi-check-all</v-icon>
            Підтвердити
          </v-btn>
        </div>
        <div class="document-table with-command mt-2" style="height: calc(80vh - 30px)">
          <div class="document-table-body">
            <div class="document-table-header">
              <div class="document-table-row">
                <div class="document-table-col" style="flex: 0 0 70px">
                  № п/п
                </div>
                <div class="document-table-col" style="flex: 0 0 360px">
                  Вид даних
                </div>
                <div class="document-table-col" style="flex: 0 0 120px">
                  До
                </div>
                <div class="document-table-col" style="flex: 0 0 120px">
                  Після
                </div>
              </div>
            </div>
            <div class="document-row-body" style="height: calc(100% - 100px); overflow: auto">
              <div class="document-table-row"
                   v-for="(charge) in change_data_table_copy"
                   :key="`charge-${charge.row_num}`"
              >
                <div class="document-table-col" style="flex: 0 0 70px; display: flex">
                  <div style="flex: 1; padding-left: 4px">
                    {{ change_data_table_copy.indexOf(charge) + 1 }}
                  </div>
                  <div style="flex:  0 0 32px">
                    <v-btn icon small class="grey lighten-3" @click="removeChangeDataTableRow(charge)">
                      <v-icon small color="grey darken-2">mdi-delete</v-icon>
                    </v-btn>
                  </div>
                </div>
                <div class="document-table-col" style="flex: 0 0 360px">
                  <v-select dense single-line hide-details
                            :items="change_data_field_select"
                            @change="onChangeDataFieldSelect($event, charge)"
                            color="grey" style="font-size: .86rem; width: 347px"
                            class="table-v-select"
                            v-model="charge.field"/>
                </div>
                <div class="document-table-col" style="flex: 0 0 120px">
                  <template v-if="charge.type === 'str'">
                    <string_input :row_num="charge.row_num"
                                  v-model="charge.before"
                                  v-decimal transparent small
                                  :required="false"
                    />
                  </template>
                  <template v-if="charge.type === 'int'">
                    <number_input :row_num="charge.row_num"
                                  v-model="charge.before"
                                  v-decimal transparent small
                                  :required="false"
                    />
                  </template>
                </div>
                <div class="document-table-col" style="flex: 0 0 120px">
                  <template v-if="charge.type === 'str'">
                    <string_input :row_num="charge.row_num"
                                  v-model.trim="charge.after"
                                  transparent small
                                  :required="false"
                    />
                  </template>
                  <template v-if="charge.type === 'int'">
                    <number_input :row_num="charge.row_num"
                                  v-model.trim="charge.after"
                                  transparent small
                                  :required="false"
                    />
                  </template>
                </div>
              </div>
            </div>
            <div class="document-table-footer mt-2">
              <div class="document-table-row">
                <div class="document-table-col"
                     :style="`flex: 0 0 670px`"
                     style="text-align: right; padding-right: 6px">
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-card>
    </v-bottom-sheet>
    <v-bottom-sheet
        right
        app
        v-model="show_workers_table"
        disable-route-watcher
        persistent
        bottom
        width="800"
    >
      <v-card min-height="80vh" class="accounting-document pretty px-4 py-2" style="overflow: hidden; position: relative">
        <div class="close-button-for-bottom-sheet" style="position: absolute; top: 0; right: 0">
          <v-btn small depressed class="grey lighten-4" @click="closeWorkerTable">
            <v-icon size="19" color="grey darken-2">mdi-close</v-icon>
          </v-btn>
        </div>
        <div class="command-panel mt-2">
          <v-btn small
                 dark
                 class="success mr-2" depressed
                 @click.stop="addWorkersTableRow"
          >
            <v-icon small>mdi-plus</v-icon>
          </v-btn>
          <v-btn small
                 dark
                 class="grey lighten-3 mr-2" depressed
                 @click.stop="clearWorkersTableDialogCopy"
          >
            <v-icon small color="error">mdi-trash-can-outline</v-icon>
          </v-btn>
          <v-btn small
                 class="grey lighten-3 mr-2" depressed
                 @click.stop="showWorkersTableSelect('workers_table')"
          >
            <v-icon small left color="teal">mdi-receipt-text-plus-outline</v-icon>
            Підбір
          </v-btn>
          <v-btn small
                 class="grey lighten-3" depressed
                 @click.stop="acceptWorkersTable"
          >
            <v-icon small left color="secondary">mdi-check-all</v-icon>
            Підтвердити
          </v-btn>
        </div>
        <div class="document-table with-command mt-2" style="height: calc(80vh - 30px)">
          <div class="document-table-body">
            <div class="document-table-header">
              <div class="document-table-row">
                <div class="document-table-col" style="flex: 0 0 70px">
                  № п/п
                </div>
                <div class="document-table-col" style="flex: 0 0 300px">
                  Виконавець
                </div>
                <div class="document-table-col" style="flex: 0 0 250px">
                  Посада
                </div>
              </div>
            </div>
            <div class="document-row-body" style="height: calc(100% - 100px); overflow: auto">
              <div class="document-table-row"
                   v-for="(charge) in workers_table_copy"
                   :key="`charge-${charge.row_num}`"
              >
                <div class="document-table-col" style="flex: 0 0 70px; display: flex">
                  <div style="flex: 1; padding-left: 4px">
                    {{ workers_table_copy.indexOf(charge) + 1 }}
                  </div>
                  <div style="flex:  0 0 32px">
                    <v-btn icon small class="grey lighten-3" @click="removeWorkersTableRow(charge)">
                      <v-icon small color="grey darken-2">mdi-delete</v-icon>
                    </v-btn>
                  </div>
                </div>
                <div class="document-table-col" style="flex: 0 0 300px">
                  <select_input
                      v-model="charge.worker_id"
                      :computed-action="'SELECT_NATURAL_PERSON_LIST_ELEMENTS'"
                      :computed-getter="'get_natural_person_list_select'"
                      :input-value-as-value="false"
                      :item-search="'text'"
                      :show-on-focus="false"
                      :row_num="charge.row_num"
                      :show-select-on-focus="false"
                      :show-search-on-focus="true"
                      :action-props="{}"
                      small
                      transparent
                      :select-button-object="{
                                name: 'NATURAL_PERSON_ELEMENTS',
                                group: '',
                                hierarchy: false,
                                only_groups: false,
                                title: 'Список виконавців',
                                element_title: 'Виконавці',
                                action_props: {},
                                show: true,
                                alternative_header_name: 'NATURAL_PERSON_FOR_DISPATCHER_ELEMENTS'
                              }"
                      :required="true"
                      @selectChange="afterWorkerSelect"
                  />
                </div>
                <div class="document-table-col" style="flex: 0 0 250px">
                  <string_input
                      v-model="charge.position_name"
                      small
                      transparent
                      disabled
                  />
                </div>
              </div>
            </div>
            <div class="document-table-footer mt-2">
              <div class="document-table-row">
                <div class="document-table-col"
                     :style="`flex: 0 0 620px`"
                     style="text-align: right; padding-right: 6px">
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-card>
    </v-bottom-sheet>
    <v-bottom-sheet
        right
        app
        v-model="show_dispatcher_problem"
        disable-route-watcher
        persistent
        bottom
        width="90%"
    >
      <list_wrapper
          :dialog="show_dispatcher_problem"
          :list_name="selection_settings.list_name"
          :list_group_name="selection_settings.list_group_name"
          :hierarchy="selection_settings.hierarchy"
          :only_groups="selection_settings.only_groups"
          :selectable="true"
          :title="selection_settings.title"
          :element_title="selection_settings.element_title"
          :modal_wrapper="true"
          :action_props="selection_settings.action_props"
          :sync_action_props="false"
          :owner_id="0"
          :document="false"
          :full_screen="false"
          :show-additional-field="false"
          :multiple="true"
          :form_id="selection_settings.form_id"
          :alternative_header_name="selection_settings.alternative_header_name"
          @closeDialog="closeListDialog"
          @selectItems="onSelectedListItems"
      />
    </v-bottom-sheet>
    <v-card-text class="pt-4" style="height: calc(100vh - 124px); overflow: auto">
      <v-row>
        <v-col cols="7">
          <v-row>
            <v-col cols="4" class="py-2">
              <date_input v-model="call_date" label="Дата дзвінка" :time="true" filled
                          :required="true"
              />
            </v-col>
            <v-col cols="4" class="py-2">
              <v-select v-model="person_type" :items="dispatcher_person_type_select" label="Тип абонента"
                        filled hide-details :class="person_type ? '' : 'req-star'"
                        color="grey darken-2"
              />
            </v-col>
            <v-col cols="4" class="py-2">
              <v-select v-model="call_type" :items="dispatcher_call_type_select" label="Тип дзвінка"
                        filled hide-details :class="call_type ? '' : 'req-star'"
                        color="grey darken-2"
              />
            </v-col>

            <v-col cols="12">
              <div class="form-group">
                <div class="form-group-title">
                  Адреса:
                </div>
                <div class="form-group-content">
                  <v-row>
                    <v-col cols="12" md="6" class="py-2">
                      <AddressElementSelect
                          label="Об’єднана громада" v-model="amalgamated_hromada_id"
                          filled select_type="amalgamated_hromada"
                          field_name="amalgamated_hromada_id"
                      />
                    </v-col>
                    <v-col cols="12" md="6" class="py-2">
                      <AddressElementSelect
                          label="Населений пункт" v-model="city_id"
                          :parent_id="amalgamated_hromada_id" :use_parent_id="true"
                          filled select_type="city"
                          field_name="city_id"
                          @selectChanged="onAddressElementChange"
                      />
                    </v-col>
                    <v-col cols="12" class="py-2">
                      <AddressElementSelect
                          label="Район міста" v-model="city_area_id"
                          :parent_id="city_id" :use_parent_id="true"
                          filled select_type="city-area"
                          field_name="city_area_id"
                      />
                    </v-col>
                    <v-col cols="12" class="py-2" v-if="city_area_id">
                      <AddressElementSelect
                          label="Вулиця" v-model="street_id"
                          :parent_id="city_area_id" :use_parent_id="true"
                          filled select_type="street-with-city-area"
                          field_name="street_id"
                          @selectChanged="onAddressElementChange"
                      />
                    </v-col>
                    <v-col cols="12" class="py-2" v-else>
                      <AddressElementSelect
                          label="Вулиця(-ці)" v-model="street_id"
                          :parent_id="city_id" :use_parent_id="true"
                          filled select_type="street"
                          field_name="street_id"
                          @selectChanged="onAddressElementChange"
                      />
                    </v-col>
                    <v-col cols="12" class="py-2" v-if="person_type === 'with_address'">
                      <AddressElementSelect
                          label="Будинок(-ки)" v-model="building_id"
                          :parent_id="street_id" :use_parent_id="true"
                          filled select_type="building"
                          field_name="building_id"
                          @selectChanged="onAddressElementChange"
                      />
                    </v-col>
                    <v-col cols="6" class="py-2" v-if="person_type === 'without_address'">
                      <v-text-field v-model.trim="building_number" label="Номер будинку" hide-details
                                    filled clearable color="grey darken-2"
                      />
                    </v-col>
                    <v-col cols="6" class="py-2" v-if="person_type === 'without_address'">
                      <v-text-field v-model.trim="flat_number" label="Номер квартири" hide-details
                                    filled clearable color="grey darken-2"/>
                    </v-col>
                    <template v-if="person_type === 'with_address'">
                      <v-col cols="8" class="py-2">
                        <AUC_FlatSearch
                            label="Абонент"
                            :flat_id="flat_id"
                            :text_value="flat_address"
                            :text_value_key="'address'"
                            placeholder="Почніть вводити текст для пошуку"
                            text_field filled
                            :custom_text_field="false"
                            :class="flat_id ? '' : 'req-star'"
                            style="padding: 0"
                            @selectChange="flatChange"
                        />
                      </v-col>
                      <v-col cols="4" class="py-2">
                        <v-text-field v-model="person_id" label="Особовий рахунок" readonly hide-details filled clearable color="grey darken-2"/>
                      </v-col>
                    </template>
                    <template v-if="person_type === 'without_address'">
                      <v-col cols="12" class="py-2">
                        <v-text-field v-model="address_represent" label="Адреса" hide-details filled clearable color="grey darken-2"/>
                      </v-col>
                    </template>

                    <v-col cols="4" class="py-2">
                      <v-text-field v-model="entrance" label="Під’їзд" hide-details filled clearable v-integer color="grey darken-2"/>
                    </v-col>
                    <v-col cols="4" class="py-2">
                      <v-text-field v-model="floor" label="Поверх" hide-details filled clearable v-integer color="grey darken-2"/>
                    </v-col>
                    <v-col cols="4" class="py-2">
                      <v-text-field v-model="intercom_code" label="Код домофону" hide-details filled clearable color="grey darken-2"/>
                    </v-col>
                  </v-row>
                </div>
              </div>
            </v-col>

            <v-col cols="12">
              <div class="form-group">
                <div class="form-group-title">
                  Дані особи, котра телефонує:
                </div>
                <div class="form-group-content">
                  <v-row>
                    <v-col cols="6" class="py-2">
                      <v-text-field v-model="flat_owner" label="ПІБ" hide-details filled clearable color="grey darken-2"/>
                    </v-col>
                    <v-col cols="6" class="py-2">
                      <v-text-field v-model="income_phone_number" label="Телефон" hide-details filled clearable color="grey darken-2"/>
                    </v-col>
                  </v-row>
                </div>
              </div>
            </v-col>

            <v-col cols="12" v-if="call_type === 'do_work'" class="pt-0">
              <v-alert
                  text
                  dense
                  color="teal"
                  icon="mdi-information-outline"
                  border="left"
                  class="mb-0 mt-0"
              >
                <div class="d-flex flex-nowrap">
                  <div style="flex: 1 1 28%">
                    <div class="d-flex">
                      <div style="flex: 0 0 130px">
                        Заборгованість:
                      </div>
                      <div style="flex: 1">
                        <span class="font-weight-medium">
                          {{ debt_sum > 0 ? `${formatToFixed(debt_sum, 2)} грн.` : 'відсутня' }}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div style="flex: 1 1 72%">
                    <div class="d-flex">
                      <div style="flex: 0 0 100px">
                        Договір:
                      </div>
                      <div style="flex: 1">
                        <span class="font-weight-medium">
                          <template v-if="contract_number && contract_date">
                            {{ `Договір №${contract_number} від ${formatDate(contract_date)}` }}
                          </template>
                          <template v-if="contract_number && !contract_date">
                            {{ `Договір №${contract_number}` }}
                          </template>
                          <template v-if="!contract_number && contract_date">
                            {{ `Договір від ${formatDate(contract_date)}` }}
                          </template>
                          <template v-if="!contract_number && !contract_date">
                            {{ `відсутній` }}
                          </template>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex flex-nowrap">
                  <div style="flex: 1 1 28%">
                    <div class="d-flex">
                      <div style="flex: 0 0 130px">
                        Зареєстровано:
                      </div>
                      <div style="flex: 1">
                        <span class="font-weight-medium">0</span> ос.
                      </div>
                    </div>
                  </div>
                  <div style="flex: 1 1 72%">
                    <div class="d-flex">
                      <div style="flex: 0 0 100px">
                        Прийняв:
                      </div>
                      <div style="flex: 1">
                        <span class="font-weight-medium">
                          {{ creator_name }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex flex-nowrap">
                  <div style="flex: 1 1 28%">
                    <div class="d-flex">
                      <div style="flex: 0 0 130px">
                        До оплати:
                      </div>
                      <div style="flex: 1">
                        <span class="font-weight-medium">
                          {{ `${formatToFixed(footer_total.work_table.sum, 2)} грн.` }}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div style="flex: 1 1 72%">
                    <div class="d-flex">
                      <div style="flex: 0 0 100px">
                        Акт:
                      </div>
                      <div style="flex: 1">
                        <span class="font-weight-medium">відсутній</span>
                      </div>
                    </div>
                  </div>
                </div>
              </v-alert>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="5">
          <v-row>
            <v-col cols="6" class="py-2">
              <v-select v-model="priority" :items="dispatcher_priority_select" label="Пріоритет"
                        filled hide-details :class="priority ? '' : 'req-star'"
                        color="grey darken-2"
              />
            </v-col>
            <v-col cols="6" class="py-2">
              <v-select v-model="status" :items="dispatcher_status_select" label="Статус"
                        filled hide-details :class="status ? '' : 'req-star'"
                        color="grey darken-2"
              />
            </v-col>

            <v-col cols="12">
              <div class="form-group">
                <div class="form-group-title">
                  Дані виклику:
                </div>
                <div class="form-group-content">
                  <v-row>
                    <v-col cols="12" class="py-2" v-if="call_type === 'do_work'">
                      <v-textarea v-model="problem_table_text" label="Текст проблеми" :auto-grow="false" rows="2" no-resize
                                  hide-details filled color="grey darken-2" readonly
                                  @click.stop="showDispatcherProblem('problem_table')"
                                  append-icon="mdi-close"
                                  append-outer-icon="mdi-vector-selection"
                                  @click:append="clearProblemsDialog"
                                  @click:append-outer="showDispatcherProblem('problem_table')"
                      />
                    </v-col>
                    <v-col cols="12" class="py-2 pb-3" v-if="call_type === 'do_work'">
                      <v-textarea v-model="work_table_text" label="Необхідні роботи" :auto-grow="false" rows="2" no-resize
                                  hide-details filled color="grey darken-2" readonly
                                  @click.stop="showWorkTable"
                                  append-icon="mdi-close"
                                  append-outer-icon="mdi-vector-selection"
                                  @click:append="clearWorkTableDialog"
                                  @click:append-outer="showDispatcherProblem('work_table_with_accept')"
                      />
                    </v-col>
                    <v-col cols="12" class="py-2" v-if="call_type === 'do_work'">
                      <v-textarea v-model="workers_table_text" label="Виконавці" :auto-grow="false" rows="2" no-resize
                                  hide-details filled color="grey darken-2" readonly
                                  @click.stop="showWorkersTable"
                                  append-icon="mdi-close"
                                  append-outer-icon="mdi-vector-selection"
                                  @click:append="clearWorkersTableDialog"
                                  @click:append-outer="showWorkersTableSelect('workers_table_with_accept')"
                      />
                    </v-col>
                    <v-col cols="12" class="py-2 pb-3" v-if="call_type === 'data_change'">
                      <v-textarea v-model="change_data_table_text" label="Суть змінених даних" :auto-grow="false" rows="2" no-resize
                                  hide-details filled color="grey darken-2" readonly
                                  @click.stop="showChangeData"
                                  append-icon="mdi-close"
                                  @click:append="clearChangeDataTableDialog"
                      />
                    </v-col>
                    <v-col cols="12" class="py-2 pb-3" :class="{'pb-4': call_type === 'data_change'}">
                      <v-textarea v-model="comment" label="Коментар" :auto-grow="true" rows="2"
                                  hide-details filled color="grey darken-2"
                      />
                    </v-col>
                  </v-row>
                </div>
              </div>
            </v-col>

            <v-col cols="12">
              <div class="form-group">
                <div class="form-group-title">
                  Результат/Реакція:
                </div>
                <div class="form-group-content">
                  <v-row>
                    <v-col cols="12" class="py-2">
                      <v-textarea v-model="reaction_text" label="Реакція замовника" :auto-grow="false" rows="1" no-resize
                                  hide-details filled color="grey darken-2"
                                  :class="call_type === 'do_work' ? 'mb-2' : ''"
                                  clearable/>
                    </v-col>
                    <v-col cols="12" class="py-2">
                      <v-textarea v-model="result_text" label="Результат виконання" :auto-grow="false" rows="1" no-resize
                                  hide-details filled color="grey darken-2"
                                  :class="call_type === 'do_work' ? 'mb-1' : ''"
                                  clearable/>
                    </v-col>
                  </v-row>
                </div>
              </div>
            </v-col>

            <v-col cols="12" v-if="call_type === 'data_change'">
              <v-alert
                  text
                  dense
                  color="teal"
                  icon="mdi-information-outline"
                  border="left"
                  class="mb-0"
              >
                <div class="d-flex flex-nowrap">
                  <div style="flex: 1 1 35%">
                    <div class="d-flex">
                      <div style="flex: 0 0 130px">
                        Заборгованість:
                      </div>
                      <div style="flex: 1">
                        <span class="font-weight-medium">
                          {{ debt_sum > 0 ? `${formatToFixed(debt_sum, 2)} грн.` : 'відсутня' }}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div style="flex: 1 1 65%">
                    <div class="d-flex">
                      <div style="flex: 0 0 100px">
                        Договір:
                      </div>
                      <div style="flex: 1">
                        <span class="font-weight-medium">
                          <template v-if="contract_number && contract_date">
                            {{ `Договір №${contract_number} від ${formatDate(contract_date)}` }}
                          </template>
                          <template v-if="contract_number && !contract_date">
                            {{ `Договір №${contract_number}` }}
                          </template>
                          <template v-if="!contract_number && contract_date">
                            {{ `Договір від ${formatDate(contract_date)}` }}
                          </template>
                          <template v-if="!contract_number && !contract_date">
                            {{ `відсутній` }}
                          </template>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex flex-nowrap">
                  <div style="flex: 1 1 35%">
                    <div class="d-flex">
                      <div style="flex: 0 0 130px">
                        Зареєстровано:
                      </div>
                      <div style="flex: 1">
                        <span class="font-weight-medium">0</span> ос.
                      </div>
                    </div>
                  </div>
                  <div style="flex: 1 1 65%">
                    <div class="d-flex">
                      <div style="flex: 0 0 100px">
                        Прийняв:
                      </div>
                      <div style="flex: 1">
                        <span class="font-weight-medium">
                          {{ creator_name }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex flex-nowrap">
                  <div style="flex: 1 1 35%">
                    <div class="d-flex">
                      <div style="flex: 0 0 130px">
                        До оплати:
                      </div>
                      <div style="flex: 1">
                        <span class="font-weight-medium">
                          {{ `${formatToFixed(footer_total.work_table.sum, 2)} грн.` }}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div style="flex: 1 1 65%">
                    <div class="d-flex">
                      <div style="flex: 0 0 100px">
                        Акт:
                      </div>
                      <div style="flex: 1">
                        <span class="font-weight-medium">відсутній</span>
                      </div>
                    </div>
                  </div>
                </div>
              </v-alert>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions style="background-color: white">
      <v-spacer></v-spacer>
      <v-btn depressed text color="secondary darken-1" @click.stop="crudItem" :loading="getModalLoading"
             class="button-accept">
        <v-icon left>mdi-content-save</v-icon>
        Зберегти
      </v-btn>
      <v-btn depressed text tile color="grey" @click="closeModal">
        <v-icon left>mdi-close</v-icon>
        Відмінити
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {round_number} from "@/utils/icons";
import {maxBy} from "lodash";
import {ALERT_SHOW} from "@/store/actions/alert";
import ModalComponentMixin from "@/mixins/modal_component";
import {QUESTION_SHOW} from "@/store/actions/question";
import DispatcherCallAPI from "@/utils/axios/dispatcher_call"
import indicatorTypeAPI from "@/utils/axios/flat_indicator"
import {formatDate, formatToFixed} from "@/filters";
import {mapGetters} from "vuex";
import {CREATE_DISPATCHER_CALL, UPDATE_DISPATCHER_CALL, DELETE_DISPATCHER_CALL} from "@/store/actions/dispatcher_call";
import {dispatcher_status_select, dispatcher_call_type_select, dispatcher_priority_select,
  dispatcher_person_type_select} from "@/utils/icons"

const modalDeleteId = 'dispatcher_call_modal_delete'

export default {
  name: "HWP_Modal_DispatcherCall",
  mixins: [ModalComponentMixin],
  computed: {
    ...mapGetters({
      user_name: 'getUserName'
    })
  },
  components: {
    date_input: () => import('@/components/accounting/input/document_date_input.vue'),
    AUC_FlatSearch: () => import('@/components/tableComponent/AUC_FlatSearch.vue'),
    AddressElementSelect: () => import('@/components/autocomplite/AddressElementSelect'),
    select_input: () => import("@/components/accounting/input/document_select_input"),
    number_input: () => import("@/components/accounting/input/document_number_input"),
    string_input: () => import("@/components/accounting/input/document_string_input"),
    list_wrapper: () => import("@/components/accounting/list_modal/list_wrapper")
  },
  data() {
    return {
      selection_settings: {
        list_name: '',
        list_group_name: '',
        hierarchy: false,
        only_groups: false,
        title: '',
        element_title: '',
        action_props: {},
        form_id: '',
        alternative_header_name: ''
      },

      call_date: this.item.call_date,
      person_type: this.item.person_type,
      call_type: this.item.call_type,
      flat_id: this.item.flat_id,
      flat_address: this.item.flat_address,
      flat_owner: this.item.flat_owner,
      person_id: this.item.person_id,
      income_phone_number: this.item.income_phone_number,
      entrance: this.item.entrance,
      floor: this.item.floor,
      intercom_code: this.item.intercom_code,
      amalgamated_hromada_id: this.item.amalgamated_hromada_id,
      city_id: this.item.city_id,
      city_area_id: this.item.city_area_id,
      street_id: this.item.street_id,
      building_id: this.item.building_id,
      priority: this.item.priority,
      status: this.item.status,
      comment: this.item.comment,
      reaction_text: this.item.reaction_text,
      result_text: this.item.result_text,
      building_number: this.item.building_number,
      flat_number: this.item.flat_number,

      problem_table_text: this.item.problem_table_text,
      problems_table: [],

      change_data_table_text: '',
      change_data_table: [],
      change_data_table_copy: [],
      show_change_data_table: false,
      change_data_field_select: [],

      add_form_id: '',

      dispatcher_person_type_select,
      dispatcher_status_select,
      dispatcher_call_type_select,
      dispatcher_priority_select,

      work_table_text: this.item.work_table_text,
      work_table: [],
      work_table_copy: [],

      workers_table_text: this.item.workers_table_text,
      workers_table: [],
      workers_table_copy: [],
      show_workers_table: false,

      show_work_table: false,
      tax_enable: false,
      tax_in_sum: false,
      footer_total: {
        work_table: {
          count: 0,
          sum: 0,
          tax: 0,
          result: 0
        }
      },

      show_dispatcher_problem: false,

      contract_date: null,
      contract_number: null,
      creator_name: null,
      debt_sum: 0,
      address_represent: '',

      document_state: {
        ready: false,
        header_ready: false,
        show: false,
        errors: []
      },
    }
  },
  methods: {
    formatDate,
    formatToFixed,
    watch_item() {
      this.watcher = this.$watch(
          'item',
          {
            deep: true,
            immediate: true,
            handler(payload) {
              this.itemId = payload.id
              this.call_date = payload.call_date || null
              this.person_type = payload.person_type || null
              this.call_type = payload.call_type || null
              this.status = payload.status || null
              this.flat_id = payload.flat_id || null
              this.flat_address = payload.flat_address || null
              this.flat_owner = payload.flat_owner || null
              this.person_id = payload.person_id || null
              this.income_phone_number = payload.income_phone_number || null
              this.entrance = payload.entrance || null
              this.floor = payload.floor || null
              this.intercom_code = payload.intercom_code || null
              this.amalgamated_hromada_id = payload.amalgamated_hromada_id || null
              this.city_id = payload.city_id || null
              this.city_area_id = payload.city_area_id || null
              this.street_id = payload.street_id || null
              this.building_id = payload.building_id || null
              this.priority = payload.priority || null
              this.comment = payload.comment || null
              this.reaction_text = payload.reaction_text || null
              this.result_text = payload.result_text || null
              this.problem_table_text = payload.problem_table_text || null
              this.add_form_id = ''
              this.work_table_text = payload.work_table_text || null
              this.workers_table_text = payload.workers_table_text || null
              this.contract_date = null
              this.contract_number = null
              this.creator_name = ''
              this.debt_sum = payload.debt_sum || 0
              this.address_represent = payload.address_represent || ''
              this.building_number = payload.building_number
              this.flat_number = payload.flat_number
              this.change_data_table_text = payload.change_data_table_text

              this.work_table = []
              this.workers_table = []
              this.problems_table = []
              this.change_data_table = []


              this.$nextTick(() => {
                if (!this.isNew) {
                  DispatcherCallAPI.get_tables(this.itemId)
                      .then(response => response.data)
                      .then(data => {
                        this.work_table = data.work_table.map((i, idx) => {
                          return Object.assign({}, i, {row_num: idx + 1})
                        })
                        this.workers_table = data.workers_table.map((i, idx) => {
                          return Object.assign({}, i, {row_num: idx + 1})
                        })
                        this.problems_table = data.problem_table.map((i, idx) => {
                          return Object.assign({}, i, {row_num: idx + 1})
                        })
                        this.change_data_table = data.change_data_table.map((i, idx) => {
                          return Object.assign({}, i, {row_num: idx + 1})
                        })
                        this.creator_name = data.creator_name

                        this.getTotals()
                      })
                } else {
                  this.creator_name = this.user_name
                }

                indicatorTypeAPI.select_type()
                    .then(response => response.data)
                    .then(data => {
                      const local_data = data.map(i => {
                        return Object.assign({}, i, {table: 'indicator_type', type: 'int'})
                      })
                      local_data.push({ text: 'ПІБ Власника', value: 'flat_owner', table: 'flat_owner', type: 'str' })
                      local_data.push({ text: 'Телефон', value: 'phone', table: 'phone', type: 'str' })
                      local_data.push({ text: 'Email', value: 'email', table: 'email', type: 'str' })
                      this.change_data_field_select = local_data
                    })
              })
            }
          }
      )
    },
    watch_modal_answer() {
      this.delete_watcher = this.$watch(
          'modalAnswer',
          {
            handler(payload) {
              if (payload.id === modalDeleteId) {
                if (payload.answer) {
                  this.$store.dispatch(DELETE_DISPATCHER_CALL, this.itemId)
                      .then(ok => {
                        if (ok) {
                          this.closeModal({fetch: true})
                        }
                      })
                }
              }
              if (payload.id === 'clear-work-table') {
                if (payload.answer) {
                  this.clearWorkTable(false)
                }
              }
              if (payload.id === 'clear-work-table-copy') {
                if (payload.answer) {
                  this.clearWorkTable(true)
                }
              }
              if (payload.id === 'clear-workers-table') {
                if (payload.answer) {
                  this.clearWorkersTable(false)
                }
              }
              if (payload.id === 'clear-workers-table-copy') {
                if (payload.answer) {
                  this.clearWorkersTable(true)
                }
              }
              if (payload.id === 'clear-problem-table') {
                if (payload.answer) {
                  this.clearProblems()
                }
              }
              if (payload.id === 'clear-change-data-table') {
                if (payload.answer) {
                  this.clearChangeDataTable(false)
                }
              }
              if (payload.id === 'clear-change-data-table-copy') {
                if (payload.answer) {
                  this.clearChangeDataTable(true)
                }
              }
            }
          }
      )
    },
    watch_dialog() {
      this.dialog_watcher = this.$watch(
          'dialog',
          {
            immediate: true,
            handler(payload) {
              if (payload) {
                this.watch_item()
                this.watch_modal_answer()
              } else {
                if (this.watcher) {
                  this.watcher()
                }
                if (this.delete_watcher) {
                  this.delete_watcher()
                }
                this.clearWorkTable()
              }
            }
          }
      )
    },
    deleteDialog() {
      this.menu = false
      const payload = {
        text: `Підтвердіть вилучення виклику у диспетчерську`,
        accept_button: true,
        id: modalDeleteId
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    closeModal(payload=null) {
      this.$emit('closeModal', payload)
      this.itemId = this.item.id
      this.call_date = this.item.call_date || null
      this.person_type = this.item.person_type || null
      this.call_type = this.item.call_type || null
      this.status = this.item.status || null
      this.flat_id = this.item.flat_id || null
      this.flat_address = this.item.flat_address || null
      this.flat_owner = this.item.flat_owner || null
      this.person_id = this.item.person_id || null
      this.income_phone_number = this.item.income_phone_number || null
      this.entrance = this.item.entrance || null
      this.floor = this.item.floor || null
      this.intercom_code = this.item.intercom_code || null
      this.amalgamated_hromada_id = this.item.amalgamated_hromada_id || null
      this.city_id = this.item.city_id || null
      this.city_area_id = this.item.city_area_id || null
      this.street_id = this.item.street_id || null
      this.building_id = this.item.building_id || null
      this.priority = this.item.priority || null
      this.comment = this.item.comment || null
      this.reaction_text = this.item.reaction_text || null
      this.result_text = this.item.result_text || null
      this.problem_table_text = this.item.problem_table_text || null
      this.add_form_id = ''
      this.work_table_text = this.item.work_table_text || null
      this.workers_table_text = this.item.workers_table_text || null
      this.contract_date = null
      this.contract_number = null
      this.creator_name = ''
      this.debt_sum = this.item.debt_sum || 0
      this.address_represent = this.item.address_represent || ''
      this.building_number = this.item.building_number
      this.flat_number = this.item.flat_number
      this.change_data_table_text = this.item.change_data_table_text

      this.work_table = []
      this.workers_table = []
      this.problems_table = []
      this.change_data_table = []
    },
    crudItem() {
      this.checkDocument()
      if (!this.document_state.ready) {
        this.document_state.show = true
        return
      }

      const payload = {
        call_date: this.call_date,
        person_type: this.person_type,
        call_type: this.call_type,
        priority: this.priority,
        status: this.status,
        flat_id: this.flat_id,
        flat_owner: this.flat_owner,
        person_id: this.person_id,
        income_phone_number: this.income_phone_number,
        entrance: this.entrance,
        floor: this.floor,
        intercom_code: this.intercom_code,
        amalgamated_hromada_id: this.amalgamated_hromada_id,
        city_id: this.city_id,
        city_area_id: this.city_area_id,
        street_id: this.street_id,
        building_id: this.building_id,
        comment: this.comment,
        reaction_text: this.reaction_text,
        result_text: this.result_text,
        problem_table_text: this.problem_table_text,
        work_table_text: this.work_table_text,
        workers_table_text: this.workers_table_text,
        debt_sum: this.debt_sum || 0,
        address_represent: this.address_represent,
        building_number: this.building_number,
        flat_number: this.flat_number,
        work_table: this.work_table,
        workers_table: this.workers_table,
        problem_table: this.problems_table,
        change_data_table: this.change_data_table,
        change_data_table_text: this.change_data_table_text,
      }

      if (this.isNew) {
        this.$store.dispatch(CREATE_DISPATCHER_CALL, payload)
            .then(el => {
              if (el) {
                this.closeModal({fetch: true})
              }
            })
      } else {
        payload.id = this.itemId
        this.$store.dispatch(UPDATE_DISPATCHER_CALL, payload)
            .then(el => {
              if (el) {
                this.closeModal({fetch: true})
              }
            })
      }
    },

    checkDocument(show=false) {
      this.document_state.show = false
      this.document_state.errors = []

      const header = {
        'call_date': {id: 'call_date', text: 'Не заповнена дата виклику'},
        'person_type': {id: 'person_type', text: 'Не заповнений тип абонента'},
        'call_type': {id: 'call_type', text: 'Не заповнений тип дзвінка'},
        'priority': {id: 'priority', text: 'Не заповнений пріорітет дзвінка'},
        'status': {id: 'status', text: 'Не заповнений статус дзвінка'},
        'flat_owner': {id: 'flat_owner', text: 'Не заповнено ПІБ особи, котра телефонує'},
      }

      if (this.person_type === 'with_address') {
        header['flat_id'] = {id: 'flat_id', text: 'Не заповнений абонент'}
      }

      if (this.person_type === 'without_address') {
        header['street_id'] = {id: 'street_id', text: 'Не заповнена вулиця'}
      }

      Object.keys(header).forEach(key => {
        if (!this[key]) {
          this.document_state.errors.push(
              {table: 'Шапка', row_num: 0, text: header[key].text}
          )
        }
      })

      this.document_state.header_ready = this.document_state.errors.length === 0;

      if (this.document_state.errors.length) {
        this.document_state.ready = false
        this.document_state.show = show
      } else {
        this.document_state.ready = true
        this.document_state.show = false
      }

    },

    closeListDialog() {
      this.show_dispatcher_problem = false
    },
    onSelectedListItems(payload) {
      const local_payload = payload || {}
      if (local_payload.form_id === 'problem_table') {
        this.problem_table_text = payload.table.map(i => i.short_name).join('; ')
        this.problems_table = payload.table.map(i => {
          return {dispatcher_problem_id: i.id}
        })

        console.log(this.problems_table)
      }

      if (local_payload.form_id === 'work_table') {
        payload.table.forEach(item => {
          const table_row = this.work_table_copy.find(i => i.dispatcher_problem_id === item.id)

          if (!table_row) {
            const row = {
              dispatcher_problem_id: item.id,
              dispatcher_problem_name: item.short_name,
              pay_service: item.pay_service,
              count: item.pay_service ? 1 : 0,
              price: item.pay_service ? item.price : 0,
              sum: 0,
              row_num: this.getNewRowNum('work_table_copy')
            }
            this.work_table_copy.push(row)

            this.workTableColChange(row)
          }
        })
        this.getTotals()
      }

      if (local_payload.form_id === 'work_table_with_accept') {
        this.work_table_copy = JSON.parse(JSON.stringify(this.work_table))
        payload.table.forEach(item => {
          const table_row = this.work_table_copy.find(i => i.dispatcher_problem_id === item.id)

          if (!table_row) {
            const row = {
              dispatcher_problem_id: item.id,
              dispatcher_problem_name: item.short_name,
              pay_service: item.pay_service,
              count: item.pay_service ? 1 : 0,
              price: item.pay_service ? item.price : 0,
              sum: 0,
              row_num: this.getNewRowNum('work_table_copy')
            }
            this.work_table_copy.push(row)

            this.workTableColChange(row)
          }
        })
        this.getTotals()

        this.work_table = JSON.parse(JSON.stringify(this.work_table_copy))
        this.work_table_text = this.work_table_copy.map(i => i.dispatcher_problem_name).join('; ')
        this.work_table_copy = []
      }

      if (local_payload.form_id === 'workers_table') {
        payload.table.forEach(item => {
          const table_row = this.workers_table_copy.find(i => i.worker_id === item.id)

          if (!table_row) {
            const row = {
              worker_id: item.id,
              worker_name: item.short_name,
              position_id: item.position_id,
              position_name: item.position_name,
              row_num: this.getNewRowNum('workers_table_copy')
            }
            this.workers_table_copy.push(row)
          }
        })
      }

      if (local_payload.form_id === 'workers_table_with_accept') {
        this.workers_table_copy = JSON.parse(JSON.stringify(this.workers_table))
        payload.table.forEach(item => {
          const table_row = this.workers_table_copy.find(i => i.worker_id === item.id)

          if (!table_row) {
            const row = {
              worker_id: item.id,
              worker_name: item.short_name,
              position_id: item.position_id,
              position_name: item.position_name,
              row_num: this.getNewRowNum('workers_table_copy')
            }
            this.workers_table_copy.push(row)
          }
        })

        this.workers_table = JSON.parse(JSON.stringify(this.workers_table_copy))
        this.workers_table_text = this.workers_table_copy.map(i => i.worker_name).join('; ')
        this.workers_table_copy = []
      }
    },

    getNewRowNum(table) {
      const max_row_item = maxBy(this[table], 'row_num')

      let max_row_num = 1
      if (max_row_item) {
        max_row_num = max_row_item.row_num + 1
      }
      return max_row_num
    },

    addWorkTableRow() {
      this.work_table_copy.push(
          {
            dispatcher_problem_id: null,
            dispatcher_problem_name: '',
            pay_service: false,
            count: 1,
            price: 0,
            sum: 0,
            row_num: this.getNewRowNum('work_table_copy'),
          }
      )
    },
    addWorkersTableRow() {
      this.workers_table_copy.push(
          {
            worker_id: null,
            worker_name: '',
            position_id: null,
            position_name: '',
            row_num: this.getNewRowNum('workers_table_copy'),
          }
      )
    },
    addChangeDataTableRow() {
      this.change_data_table_copy.push(
          {
            field: null,
            type: null,
            before: null,
            after: null,
            row_num: this.getNewRowNum('change_data_table_copy'),
          }
      )
    },

    removeWorkTableRow(payload) {
      this.work_table_copy.splice(this.work_table.indexOf(payload), 1)
    },
    removeWorkersTableRow(payload) {
      this.workers_table_copy.splice(this.workers_table_copy.indexOf(payload), 1)
    },
    removeChangeDataTableRow(payload) {
      this.change_data_table_copy.splice(this.change_data_table_copy.indexOf(payload), 1)
    },

    clearProblemsDialog() {
      const payload = {
        text: `Підтвердіть очищення проблем. Зміни збережені не будуть.`,
        accept_button: true,
        id: 'clear-problem-table'
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    clearWorkTableDialog() {
      const payload = {
        text: `Підтвердіть очищення таблиці робіт. Зміни збережені не будуть.`,
        accept_button: true,
        id: 'clear-work-table'
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    clearWorkTableDialogCopy() {
      const payload = {
        text: `Підтвердіть очищення таблиці робіт. Зміни збережені не будуть.`,
        accept_button: true,
        id: 'clear-work-table-copy'
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    clearWorkersTableDialog() {
      const payload = {
        text: `Підтвердіть очищення таблиці виконавців. Зміни збережені не будуть.`,
        accept_button: true,
        id: 'clear-workers-table'
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    clearWorkersTableDialogCopy() {
      const payload = {
        text: `Підтвердіть очищення таблиці виконавців. Зміни збережені не будуть.`,
        accept_button: true,
        id: 'clear-workers-table-copy'
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },

    clearChangeDataTableDialog() {
      const payload = {
        text: `Підтвердіть очищення таблиці змін даних. Зміни збережені не будуть.`,
        accept_button: true,
        id: 'clear-change-data-table'
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    clearChangeDataTableDialogCopy() {
      const payload = {
        text: `Підтвердіть очищення таблиці виконавців. Зміни збережені не будуть.`,
        accept_button: true,
        id: 'clear-change-data-table-copy'
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },

    clearProblems() {
      this.problems_table = []
      this.problem_table_text = ''
    },
    clearWorkTable(copy=true) {
      if (copy) {
        this.work_table_copy = []
      } else {
        this.work_table_copy = []
        this.work_table = []
        this.work_table_text = ''
      }
      this.getTotals()
    },
    clearWorkersTable(copy=true) {
      if (copy) {
        this.workers_table_copy = []
      } else {
        this.workers_table = []
        this.workers_table_copy = []
        this.workers_table_text = ''
      }
    },
    clearChangeDataTable(copy=true) {
      if (copy) {
        this.change_data_table_copy = []
      } else {
        this.change_data_table = []
        this.change_data_table_copy = []
        this.change_data_table_text = ''
      }
    },

    closeWorkTable() {
      this.show_work_table = false
    },
    closeChangeDataTable() {
      this.show_change_data_table = false
    },
    closeWorkerTable() {
      this.show_workers_table = false
    },

    acceptWorkTable() {
      let errors = this.work_table_copy.reduce((acc, item) => {
        if (!item.dispatcher_problem_id || !item.count || !item.price) {
          if (item.pay_service) {
            return acc + 1
          } else {
            return acc + 0
          }
        } else {
          return acc + 0
        }
      }, 0)

      if (errors) {
        this.$store.commit(ALERT_SHOW, { text: 'Виявлені помилки у переліку робіт. Виправте поля підкреслені червоним кольором', color: 'error' })
        return null
      }

      this.work_table_text = this.work_table_copy.map(i => i.dispatcher_problem_name).join('; ')
      this.show_work_table = false
      this.work_table = JSON.parse(JSON.stringify(this.work_table_copy))
    },
    acceptWorkersTable() {
      let errors = this.workers_table_copy.reduce((acc, item) => {
        if (!item.worker_id || !item.position_id) {
          return acc + 1
        } else {
          return acc + 0
        }
      }, 0)

      if (errors) {
        this.$store.commit(ALERT_SHOW, { text: 'Виявлені помилки у переліку виконавців. Виправте поля підкреслені червоним кольором', color: 'error' })
        return null
      }

      this.workers_table_text = this.workers_table_copy.map(i => i.worker_name).join('; ')
      this.show_workers_table = false
      this.workers_table = JSON.parse(JSON.stringify(this.workers_table_copy))
    },
    acceptChangeDataTable() {
      let errors = this.change_data_table_copy.reduce((acc, item) => {
        if (!item.field || !item.type) {
          return acc + 1
        } else {
          return acc + 0
        }
      }, 0)

      if (errors) {
        this.$store.commit(ALERT_SHOW, { text: 'Виявлені помилки у переліку змін даних. Виправте поля підкреслені червоним кольором', color: 'error' })
        return null
      }

      this.change_data_table_text = this.change_data_table_copy.map(i => {
        const field = this.change_data_field_select.find(a => a.value === i.field)
        if (field) {
          return `${field.text}: До: ${i.before}; Після: ${i.after}`
        }
      }).join('; ')
      this.show_change_data_table = false
      this.change_data_table = JSON.parse(JSON.stringify(this.change_data_table_copy))
    },
    afterNomenclatureSelect(payload) {
      const val = payload || {}
      if (val.row_num) {
        const row = this.work_table_copy.find(item => item.row_num === val.row_num)
        if (row) {
          row.dispatcher_problem_name = val.text || val.short_name || val.full_name || val.name
          this.workTableColChange(val)
        }
      }
    },
    afterWorkerSelect(payload) {
      const val = payload || {}
      if (val.row_num) {
        const row = this.workers_table_copy.find(item => item.row_num === val.row_num)
        if (row) {
          row.worker_name = val.text || val.short_name || val.full_name || val.name
          row.position_name = val.type || val.position_name || ''
          row.position_id = val.position_id || val.numeric_value
        }
      }
    },
    getTotals() {
      this.footer_total.work_table = this.work_table_copy.reduce((acc, item) => {
        acc.count += item.count || 0
        acc.sum += item.sum || 0
        acc.tax += item.tax || 0
        acc.result += item.result || 0
        return acc
      }, {count: 0, sum: 0, tax: 0, result: 0})
    },
    calcRow(payload, col = '') {
      if (!payload) return
      const tax_tariff = null

      if (col === 'sum') {
        payload.price = round_number(payload.sum / payload.count, 4)
      }
      if (this.tax_enable) {
        if (col === 'tax') {
          if (this.tax_in_sum) {
            payload.tax = this.round_number(payload.sum / (100 / tax_tariff + 1), 2)
            payload.result = payload.sum
          } else {
            payload.tax = this.round_number(payload.sum * tax_tariff / 100, 2)
            payload.result = this.round_number(payload.sum + payload.tax, 2)
          }
          return;
        }
      } else {
        payload.tax = 0
        payload.result = payload.sum
      }

      payload.sum = round_number(payload.count * payload.price, 2)

      if (this.tax_enable) {
        if (this.tax_in_sum) {
          payload.tax = this.round_number(payload.sum / (100 / tax_tariff + 1), 2)
          payload.result = payload.sum
        } else {
          payload.tax = this.round_number(payload.sum * tax_tariff / 100, 2)
          payload.result = this.round_number(payload.sum + payload.tax, 2)
        }
      } else {
        payload.tax = 0
        payload.result = payload.sum
      }

      this.getTotals()
    },
    workTableColChange(payload) {
      this.$nextTick(() => {
        const row = this.work_table_copy.find(i => i.row_num === payload.row_num)
        if (!row) return

        this.calcRow(row, payload.field)
        this.getTotals()
      })
    },
    showWorkTable() {
      this.show_work_table = false
      this.work_table_copy = JSON.parse(JSON.stringify(this.work_table))
      this.show_work_table = true
    },
    showWorkersTable() {
      this.show_workers_table = false
      this.workers_table_copy = JSON.parse(JSON.stringify(this.workers_table))
      this.show_workers_table = true
    },
    showDispatcherProblem(form_id) {
      this.selection_settings.list_name = 'DISPATCHER_PROBLEM_ELEMENTS'
      this.selection_settings.list_group_name = 'DISPATCHER_PROBLEM_GROUP'
      this.selection_settings.hierarchy = true
      this.selection_settings.only_groups = false
      this.selection_settings.title = 'Список проблем/робіт диспетчерської'
      this.selection_settings.action_props = {is_group: false}
      this.selection_settings.form_id = form_id
      this.show_dispatcher_problem = true
      this.alternative_header_name = ''
    },
    showChangeData() {
      this.show_change_data_table = false

      if (!this.flat_id) {
        this.$store.commit(ALERT_SHOW, {text: 'Зміна даних доступна лише, коли вказаний абонент', color: 'error'})
        return
      }
      this.change_data_table_copy = JSON.parse(JSON.stringify(this.change_data_table))
      this.show_change_data_table = true
    },
    showWorkersTableSelect(form_id) {
      this.selection_settings.list_name = 'NATURAL_PERSON_ELEMENTS'
      this.selection_settings.list_group_name = ''
      this.selection_settings.hierarchy = false
      this.selection_settings.only_groups = false
      this.selection_settings.title = 'Список виконавців'
      this.selection_settings.action_props = {}
      this.selection_settings.form_id = form_id
      this.show_dispatcher_problem = true
      this.selection_settings.alternative_header_name = 'NATURAL_PERSON_FOR_DISPATCHER_ELEMENTS'
    },
    flatChange(payload) {
      const local_data = payload || {}

      if (local_data.value) {
        DispatcherCallAPI.get_info(local_data.value)
            .then(response => response.data)
            .then(data => {
              this.debt_sum = data.debt || 0
              this.contract_date = data.contract_date
              this.contract_number = data.contract_number
              this.flat_owner = data.owner_name
              this.income_phone_number = data.phone
              this.person_id = data.person_id
              this.creator_name = data.creator
              this.amalgamated_hromada_id = data.amalgamated_hromada_id
              this.city_id = data.city_id
              this.city_area_id = data.city_area_id
              this.street_id = data.street_id
              this.building_id = data.building_id
              this.entrance = data.entrance
              this.floor = data.floor
            })
            .catch(err => {
              const error = err.response.data.detail;
              this.$store.commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
            })
      } else {
        this.debt_sum = 0
        this.contract_date = null
        this.contract_number = null
        this.flat_owner = null
        this.income_phone_number = null
        this.person_id = null
        this.creator_name = null
        this.amalgamated_hromada_id = null
        this.city_id = null
        this.city_area_id = null
        this.street_id = null
        this.building_id = null
        this.entrance = null
        this.floor = null
      }
      this.flat_address = local_data.address
      this.flat_id = local_data.value
      this.address_represent = `${this.flat_address}`
    },
    onAddressElementChange(payload) {
      this.address_represent = `${payload.text || ''}`

      if (payload.field === 'street_id' && this.person_type === 'without_address') {
        if (this.flat_number && this.building_number) {
          this.address_represent = `${payload.text || ''} буд. ${this.building_number} кв. ${this.flat_number}`
        }
        if (!this.flat_number && this.building_number) {
          this.address_represent = `${payload.text || ''} буд. ${this.building_number}`
        }
      }
    },
    onChangeDataFieldSelect(e, payload) {
      const selected_row = this.change_data_field_select.find(i => i.value === e) || {}

      payload.type = selected_row.type

      if (!this.flat_id) return

      const payload_for_change_data = {
        flat_id: this.flat_id,
        table: selected_row.table,
        value: e
      }
      DispatcherCallAPI.get_info_for_change_data(payload_for_change_data)
          .then(response => response.data)
          .then(data => {
            payload.before = data
          })
    }
  }
}
</script>

<style scoped lang="scss">
  .form-group {
    border: 1px solid var(--v-success-base);
    border-radius: 8px;
    padding: 0 20px;
    position: relative;

    .form-group-title {
      position: absolute;
      top: -12px;
      left: 12px;
      padding-left: 6px;
      padding-right: 6px;
      background: white;
      font-size: 1rem;
      font-weight: 500;
      color: var(--v-success-base);
    }
    .form-group-content {
      padding: 20px 0;
    }
  }
  .table-v-select {
    margin-top: 0 !important;

    &:deep(.v-select__selection.v-select__selection--comma) {
      margin-top: 1px !important;
    }
  }
</style>